<script setup lang="ts">
import { ref } from "vue";
import {
  useFloating,
  offset,
  autoUpdate,
  detectOverflow,
  type MiddlewareState,
} from "@floating-ui/vue";
import { onClickOutside } from "@vueuse/core";

const props = defineProps<{
  content: string;
  boundary?: HTMLElement;
  floatingClass?: string;
}>();

const reference = ref<HTMLElement | null>(null);
const floating = ref<HTMLElement | null>(null);
const open = ref(false);

function toggle() {
  open.value = !open.value;
}

const placementMiddleware = {
  name: "placement",
  async fn(state: MiddlewareState) {
    const overflow = await detectOverflow(state, {
      padding: 15,
      boundary: props.boundary,
    });

    if (overflow.right > 0) {
      return { x: state.x - overflow.right };
    }

    if (overflow.left > 0) {
      return { x: state.x + overflow.left };
    }

    return {};
  },
};

const { floatingStyles } = useFloating(reference, floating, {
  open: open.value,
  middleware: [
    offset(
      ({ rects }) => -rects.reference.height / 2 - rects.floating.height / 2
    ),
    placementMiddleware,
  ],
  whileElementsMounted: autoUpdate,
  strategy: "absolute",
  placement: "bottom",
});

onClickOutside(
  reference,
  () => {
    open.value = false;
  },
  {
    ignore: [reference],
  }
);
</script>

<template>
  <div ref="reference">
    <slot :toggle="toggle" />
    <div
      v-show="open"
      ref="floating"
      :style="floatingStyles"
      :class="floatingClass"
    >
      <slot name="content" :content="content" />
    </div>
  </div>
</template>
