<template>
  <div ref="el" class="relative">
    <JBorder
      ref="el"
      class="!absolute inset-0 rounded-[8px] z-10"
      :style="{ maskImage }"
      :background-style="{
        backgroundColor: 'transparent',
      }"
    />

    <div
      class="relative rounded-[8px] border border-transparent overflow-hidden z-20"
    >
      <div class="w-full backdrop-blur-[10px] bg-white bg-opacity-60">
        <div class="p-10 text-left font-[650] text-sm leading-[23px]">
          <p class="max-w-[262px]">
            <slot />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useElementBounding } from "@vueuse/core";
import JBorder from "./JBorder.vue";

const el = ref(null as unknown as HTMLElement);

const bounding = useElementBounding(el);

const maskImage = computed(() => {
  const width = bounding.width.value;
  const height = bounding.height.value;

  // prettier-ignore
  const svg = `<svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}'><rect x='0.5' y='0.5' width='${width - 1}' height='${height - 1}' stroke='white' fill='none' rx='7.5'></rect></svg>`;

  return `url("data:image/svg+xml;charset=utf8,${svg}")`;
});
</script>
