<template>
  <div ref="root" class="mt-12 max-w-[718px] px-[15px] mx-auto">
    <div
      class="flex flex-wrap text-xs leading-[15px] justify-center -mx-[5.5px] text-center"
    >
      <template v-for="(item, index) in items" :key="index">
        <Tooltip
          v-if="item !== 'break'"
          :content="item.description"
          :class="['flex w-1/2 sm:w-auto px-[5.5px] mb-[11px]', item.classes]"
          :boundary="root"
          floating-class="max-w-[calc(100%_-_30px)]"
        >
          <template v-slot="{ toggle }">
            <button
              class="w-full h-full border border-gray-400 px-[17px] py-[15px] rounded-[5px] text-[12px] font-[670] leading-[14px] sm:whitespace-nowrap"
              type="button"
              @click="toggle"
            >
              {{ item.label }}
            </button>
          </template>
          <template #content="{ content }">
            <FeatureTooltip>
              {{ content }}
            </FeatureTooltip>
          </template>
        </Tooltip>
        <div v-else class="hidden sm:block basis-[100%]"></div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import FeatureTooltip from "./FeatureTooltip.vue";
import Tooltip from "./Tooltip.vue";

const root = ref(null as unknown as HTMLElement);

const items: (
  | { label: string; description: string; classes?: string }
  | "break"
)[] = [
  {
    label: "Personalized subscription plans",
    description:
      "Segment your customers and create a personalized subscription experience.",
    classes: "order-[-1] sm:order-[unset]",
  },

  {
    label: "Adjustable email notifications",
    description:
      "Adjust notification flows and build engaging, brand-appropriate communication.",
    classes: "order-[-1] sm:order-[unset]",
  },

  {
    label: "Full subscriber management",
    description:
      "Have full control over subscriber and subscription management.",
    classes: "order-[-1] sm:order-[unset]",
  },

  {
    label: "Cancellation flows",
    description: "Increase retention with custom cancellation flows.",
  },

  {
    label: "Welcome boxes",
    description:
      "Attract and acquire new subscribers with personalized welcome boxes.",
  },

  {
    label: "Dynamic boxes",
    description: "Create dynamic subscriptions with automatic SKU swapping.",
  },

  {
    label: "Engaging experience",
    description:
      "Elevate the subscriber experience with personalized upsells, free add-ons, and flexible discounts.",
    classes: "order-[-1] sm:order-[unset]",
  },

  {
    label: "On-brand Customer Portal",
    description:
      "Let customers easily manage their subscriptions in the mobile-first, on-brand Customer Portal.",
    classes: "order-[-1] sm:order-[unset]",
  },

  {
    label: "Memberships",
    description:
      "Launch your own membership program and build loyalty among your customers.",
  },

  {
    label: "Personalized upsells",
    description:
      "Offer contextual upsell suggestions to increase the value of subscription and one-time orders.",
    classes: "order-[-1] sm:order-[unset]",
  },

  "break",

  {
    label: "Flexible subscribe & save",
    description:
      "Create custom subscription plans with dynamic discounts and flexible purchase options.",
  },

  {
    label: "Robust analytics",
    description:
      "Uncover patterns and customer insights with a comprehensive subscription analytics dashboard.",
  },
];
</script>
